import React from "react"
import { Helmet } from "react-helmet-async"
import Layout from "../components/layout"
import { Link } from "gatsby"

export default ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>Not Found!</title>
      </Helmet>
      <h1>Uh oh!</h1>
      <p>Looks like that page doesn’t exist! <Link to='/'>Try visiting the home page instead</Link>.</p>
    </Layout>
  )
}

